import CityDisplay from '../../pages/design/city-display.json';
import TenderSearch from '../../pages/design/tender-search.json';
import DesignSystem from '../../pages/design/multi-brand-design-system.json';
import FlamesBarcelona from '../../pages/design/branding-a-running-crew.json';
import TwitterEmotions from '../../pages/design/emotions-in-tweets.json';
import CitizenUX from '../../pages/design/citizen-ux.json';
import ReachActivation from '../../pages/design/reach-and-activation.json';
import Address from '../../pages/design/address-input-field.json';

const Confidential = [
  /*  {
    title: 'Citizen experience',
    description:
      'Redesigning the UX of citizen-facing commercial products for local governments',
    isPrivate: true,
  }, */
];

const Teasers = [
  /* {
    title: 'Citizen authentication',
    description:
      'An exploration of a user-centered, mobile-first authentication flow for e-government services',
    isPlaceholder: true,
  }, */
  {
    title: 'Destructuring design',
    year: '2023',
    description: 'A living visual glossary of product design terminology',
    isPlaceholder: true,
  },
];

const Portfolio = [
  CityDisplay,
  TenderSearch,
  DesignSystem,
  Address,
  CitizenUX,
  ReachActivation,
  ...Confidential,
  FlamesBarcelona,
  TwitterEmotions,
  ...Teasers,
];

export default Portfolio;

import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';

import Resume from '@sections/Resume';
import Design from '@sections/Design';
import Photography from '@sections/Photography';
import Flames from '@sections/Flames';

import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />

    <Header />

    <Resume />
    <Design />
    <Photography />
    <Flames />

    <Footer />
  </Layout>
);

export default IndexPage;

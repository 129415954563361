import React, { useEffect } from 'react';
import styled from 'styled-components';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { throttle } from 'lodash';

import {
  Section,
  Container,
  Heading,
  Text,
  Quote,
  RunningText,
} from '@components/global';
import ExternalLink from '@common/ExternalLink';
import { useMousePosition } from '@components/hooks/useMousePosition';

function parallax(pos) {
  document.querySelectorAll('div[data-layer]').forEach((layer) => {
    const speed = layer.getAttribute('data-speed');
    const x = (window.innerWidth - pos.x * speed) / 100;
    const y = (window.innerHeight - pos.y * speed) / 100;
    layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
  });
}

const fps = 60;
const delayedParallax = throttle((pos) => parallax(pos), 1000 / fps);

const Page = () => {
  const position = useMousePosition();
  const speeds = [
    3, 1, 6, 2, 7, -6, 4, -5, 6, 2, -8, 9, 3, 1, 3, 1, -1, -1, 1, 6,
  ];

  useEffect(() => {
    delayedParallax(position);
  }, [position]);

  return (
    <StaticQuery
      query={graphql`
        query {
          flames: allFile(
            filter: { sourceInstanceName: { eq: "flames" } }
            sort: { fields: name, order: ASC }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(maxHeight: 640, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section id="co-founder" accent>
          <Container>
            <Heading>
              <h2>
                <RunningText>Make running great again.</RunningText>
              </h2>
            </Heading>
            <Text>
              <Quote>
                “Be yourself; everyone else is already taken.” ― Oscar Wilde
              </Quote>
              <p>
                In 2018 I co-founded Flames Barcelona, an urban running crew in
                Barcelona. With Fabien and Oualid, we've drafted events and
                experiences, brought together a community, set a brand,
                established a social media presence, and networked with local
                and international communities.
              </p>
              <ExternalLink href="https://flamesbarcelona.com">
                flamesbarcelona.com
              </ExternalLink>
            </Text>
          </Container>

          <Grid>
            {data.flames.edges.map(({ node }, index) => {
              return (
                <Item
                  position={index}
                  data-layer
                  data-index={index}
                  data-speed={speeds[index]}
                  key={node.id}
                >
                  <Art fluid={node.childImageSharp.fluid} />
                </Item>
              );
            })}
          </Grid>
        </Section>
      )}
    />
  );
};

// Grid of images

const Grid = styled.div`
  // pointer-events: none;
  // position: absolute;
  width: 100%;
  height: 100%;
  height: 175vh;
  padding: 10vh 0;
  display: grid;
  grid-template-columns: repeat(50, 2%);
  grid-template-rows: repeat(50, 2%);
  overflow-x: hidden;
  overflow-y: visible;
`;

const positions = {
  0: '10 / 1 / 13 / 6',
  1: '2 / 18 / 10 / 29',
  2: '3 / 36 / 11 / 40',
  3: '13 / 11 / 26 / 18',
  4: '17 / 32 / 29 / 37',
  5: '20 / 46 / 28 / 51',
  6: '35 / 2 / 49 / 10',
  7: '38 / 14 / 51 / 22',
  8: '17 / 5 / 27 / 10',
  9: '29 / 39 / 43 / 45',
  10: '26 / 23 / 38 / 30',
  11: '11 / 22 / 17 / 28',
  12: '1 / 41 / 14 / 47',
  13: '21 / 16 / 39 / 23',
  14: '18 / 38 / 30 / 45',
  15: '45 / 35 / 51 / 51',
  16: '29 / 2 / 34 / 15',
  17: '1 / 3 / 7 / 17',
  18: '10 / 38 / 17 / 45',
  19: '36 / 33 / 45 / 38',
};

const mobilePositions = {
  0: 'none', // 0: '10 / 1 / 13 / 6',
  1: '2 / 8 / 8 / 32', // 1: '2 / 18 / 10 / 29',
  2: '3 / 30 / 14 / 45', // 2: '3 / 36 / 11 / 40',
  3: '10 / 1 / 26 / 23', // 3: '13 / 11 / 26 / 18',
  4: '17 / 22 / 29 / 37', // 4: '17 / 32 / 29 / 37',
  5: 'none', // 5: '20 / 46 / 28 / 51',
  6: '35 / 2 / 49 / 10',
  7: '40 / 14 / 51 / 30', // 7: '38 / 14 / 51 / 22',
  8: '17 / 5 / 27 / 10',
  9: '31 / 38 / 43 / 51', // 9: '29 / 39 / 43 / 45',
  10: '26 / 23 / 38 / 30',
  11: '11 / 22 / 17 / 28',
  12: 'none', // 12: '1 / 41 / 14 / 47',
  13: '23 / 8 / 39 / 23', // 13: '21 / 16 / 39 / 23',
  14: '18 / 36 / 32 / 49', // 14: '18 / 38 / 30 / 45',
  15: 'none', // 15: '45 / 35 / 51 / 51',
  16: 'none', // 16: '29 / 2 / 34 / 15',
  17: 'none', // 17: '1 / 3 / 7 / 17',
  18: 'none', // 18: '10 / 38 / 17 / 45',
  19: '36 / 27 / 45 / 41', // 19: '36 / 33 / 45 / 38',
};

const Item = styled.div`
  position: relative;
  transition: ${2 / fps}s ease-in-out;
  grid-area: ${(props) => positions[props.position]};
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-area: ${(props) => mobilePositions[props.position]};
  }
`;

const Art = styled(Img)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export default Page;

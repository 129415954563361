import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import {
  Container,
  Heading,
  DesignText,
  TechnologyText,
  PhotographyText,
} from '@components/global';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        header_portrait: file(
          sourceInstanceName: { eq: "header" }
          name: { eq: "portrait" }
        ) {
          childImageSharp {
            fluid(maxHeight: 960, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper id="header">
        <Container>
          <Heading>
            <h1>
              I merge <TechnologyText dark>technology</TechnologyText>,{' '}
              <DesignText dark>design</DesignText> and{' '}
              <PhotographyText dark>creative arts</PhotographyText>, and try to
              have fun with them
            </h1>
          </Heading>
          <Art>
            <Img
              fluid={data.header_portrait.childImageSharp.fluid}
              style={{
                zIndex: 0,
              }}
            />
          </Art>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background};
  color: ${props => props.theme.color.black.lighter};
  padding-top: 96px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 28px;
  }
`;

const Art = styled.figure`
  width: 50%;
  position: relative;
  margin: 0 auto;
  margin-top: -2rem;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 80%;
    margin-top: -2rem;
  }
`;

export default Header;

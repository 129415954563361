import React from 'react';
import styled from 'styled-components';

import { StaticQuery, graphql } from 'gatsby';

import {
  Section,
  Container,
  Heading,
  Text,
  PhotographyText,
  Quote,
} from '@components/global';

import WidescreenMp4 from '@videos/widescreen.mp4';
import PortraitMp4 from '@videos/portrait.mp4';
import WidescreenPoster from '@videos/widescreen.png';
import PortraitPoster from '@videos/portrait.png';

const Page = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          photography: allFile(
            filter: { sourceInstanceName: { eq: "photography" } }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(maxHeight: 960, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Section id="visual-creator" disablePadding>
          <Container>
            <Heading>
              <h2>
                <PhotographyText>Seen through my lens.</PhotographyText>
              </h2>
            </Heading>
            <Text>
              <Quote>
                “When do I see a photograph, when a reflection?” ― Philip K.
                Dick, A Scanner Darkly
              </Quote>
              <p>From time to time, I get out and shoot stuff.</p>
            </Text>
          </Container>

          <VideoPlayer
            preload="auto"
            poster={WidescreenPoster}
            loop
            autoPlay
            muted
          >
            <source
              src={WidescreenMp4}
              type="video/mp4"
              crossOrigin="anonymous"
            />
          </VideoPlayer>
          <MobileVideoPlayer
            preload="auto"
            poster={PortraitPoster}
            loop
            autoPlay
            muted
          >
            <source
              src={PortraitMp4}
              type="video/mp4"
              crossOrigin="anonymous"
            />
          </MobileVideoPlayer>

          {/*
          <Container>
            <Text>
              <p>
                Shooting is something that I enjoy and that I would love to do
                more than I currently get to do. What you saw above are some of
                my photographies from the last 10 years.
              </p>
              <p>Have an idea? Get in touch!</p>
            </Text>
          </Container>
          */}
        </Section>
      )}
    />
  );
};

// Video

const VideoPlayer = styled.video`
  width: 100%;
  display: block;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const MobileVideoPlayer = styled.video`
  width: 100%;
  display: none;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

// Grid of images
/*
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 100px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  padding-bottom: 10vh;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const Art = styled(Img)`
  width: 100%;
`;
*/
export default Page;
